@font-face {
    font-family: 'latolight';
    src: url('fonts/lato-light-webfont.woff2') format('woff2'),
         url('fonts/lato-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'latobold';
    src: url('fonts/lato-bold-webfont.woff2') format('woff2'),
         url('fonts/lato-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'robotolight';
    src: url('fonts/roboto-light-webfont.woff2') format('woff2'),
         url('fonts/roboto-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'robotolight_italic';
    src: url('fonts/roboto-lightitalic-webfont.woff2') format('woff2'),
         url('fonts/roboto-lightitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'robotoregular';
    src: url('fonts/roboto-regular-webfont.woff2') format('woff2'),
         url('fonts/roboto-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'robotoitalic';
    src: url('fonts/roboto-italic-webfont.woff2') format('woff2'),
         url('fonts/roboto-italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'robotomedium';
    src: url('fonts/roboto-medium-webfont.woff2') format('woff2'),
         url('fonts/roboto-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'robotobold';
    src: url('fonts/roboto.bold-webfont.woff2') format('woff2'),
         url('fonts/roboto.bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'helen2_bgmedium';
    src: url('fonts/helen2_bg_medium-webfont.woff2') format('woff2'),
         url('fonts/helen2_bg_medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}