/********************************/
/*          Media Queries       */
/********************************/
@media (min-width: 992px) and (max-width: 1200px ) {
  .panel {
    width: 250px;
      height: 100% !important;
  }

  .panel-inner {
    margin-bottom: 0 !important;
    margin-top: 20px;
  }
  .panel-start {
    margin-bottom: 15px;
  }
  .equal{
      margin-top: 50px;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        margin-left: -180px;
        flex-flow: wrap;
    }
}
@media (min-width: 1200px) {
  #jay-signature-pad {
    width: 400px !important;
    height: 260px !important;
  }
  .panel {
    width: 300px;
      height: 100% !important;
  }

  .panel-inner {
    margin-bottom: 0 !important;
    margin-top: 20px;
  }
  .panel-start {
    margin-bottom: 15px;
  }
  .equal{
      margin-top: 50px;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        margin-left: -180px;
        flex-flow: wrap;
    }
  .banner-content {
    left: 39%;
  }
  .col-lg-5th-1, .col-lg-5th-2, .col-lg-5th-3, .col-lg-5th-4{
    float: left;
  }
  .col-lg-5th-5{
    float: left;
    width: 100%;
  }
  .col-lg-5th-4{
    width: 80%;
  }
  .col-lg-5th-3{
    width: 60%;
  }
  .col-lg-5th-2{
    width: 40%;
  }
  .col-lg-5th-1{
    width: 20%;
  }
  .col-lg-5th-pull-5{
    right: 100%;
  }
  .col-lg-5th-pull-4{
    right: 80%;
  }
  .col-lg-5th-pull-3{
    right: 60%;
  }
  .col-lg-5th-pull-2{
    right: 40%;
  }
  .col-lg-5th-pull-1{
    right: 20%;
  }
  .col-lg-5th-pull-0{
    right: auto;
  }
  .col-lg-5th-push-5{
    left: 100%;
  }
  .col-lg-5th-push-4{
    left: 80%;
  }
  .col-lg-5th-push-3{
    left: 60%;
  }
  .col-lg-5th-push-2{
    left: 40%;
  }
  .col-lg-5th-push-1{
    left: 20%;
  }
  .col-lg-5th-push-0{
    left: auto;
  }
  .col-lg-5th-offset-5{
    margin-left: 100%;
  }
  .col-lg-5th-offset-4{
    margin-left: 80%;
  }
  .col-lg-5th-offset-3{
    margin-left: 60%;
  }
  .col-lg-5th-offset-2{
    margin-left: 40%;
  }
  .col-lg-5th-offset-1{
    margin-left: 20%;
  }
  .col-lg-5th-offset-0{
    margin-left: 0%;
  }
}
@media(max-width: 1350px) {
  .banner-content {
    left: 32%;
  }
}

@media(max-width: 1199px) {
  .banner-content {
    top: 60%;
    left: 35%;
  }
  .navbar-brand img{
    margin-left: 15px; 
  }
  .request-header {
    margin-left: 0; 
  }
  .about-us-info-image img {
    top: 150px; 
    left: -70px; 
  }
  .references-info-image img{
    bottom: 0px;
    right: 0px; 
  }
  #services_rq table {
    margin-bottom: 30px; 
    margin-left: -100;
    width: 900px;
    border-collapse: separate;
      border-spacing: 1px;
      opacity: 1;
  }
  .table-responsive{
    overflow-x: auto;
  }
  div#datatable_filter {
    margin-left: 455px !important;
  }
}

@media (max-width: 1270px) {
  #jay-signature-pad {
    width: 400px !important;
    height: 260px !important;
  }
  .banner-content {
    top: 60%;
    left: 35%;
  }
  .partner-logos a {
    padding-left: 0; 
  }
  div#datatable_filter {
    margin-left: 455px !important;
  }
}

@media (min-width: 992px) {
  .col-md-5th-1, .col-md-5th-2, .col-md-5th-3, .col-md-5th-4{
    float: left;
  }
  .col-md-5th-5 {
    float: left;
    width: 100%;
  }
  .col-md-5th-4{
    width: 80%;
  }
  .col-md-5th-3{
    width: 60%;
  }
  .col-md-5th-2{
    width: 40%;
  }
  .col-md-5th-1{
    width: 20%;
  }
  .col-md-5th-pull-5{
    right: 100%;
  }
  .col-md-5th-pull-4{
    right: 80%;
  }
  .col-md-5th-pull-3{
    right: 60%;
  }
  .col-md-5th-pull-2{
    right: 40%;
  }
  .col-md-5th-pull-1{
    right: 20%;
  }
  .col-md-5th-pull-0{
    right: auto;
  }
  .col-md-5th-push-5{
    left: 100%;
  }
  .col-md-5th-push-4{
    left: 80%;
  }
  .col-md-5th-push-3{
    left: 60%;
  }
  .col-md-5th-push-2{
    left: 40%;
  }
  .col-md-5th-push-1{
    left: 20%;
  }
  .col-md-5th-push-0{
    left: auto;
  }
  .col-md-5th-offset-5{
    margin-left: 100%;
  }
  .col-md-5th-offset-4{
    margin-left: 80%;
  }
  .col-md-5th-offset-3{
    margin-left: 60%;
  }
  .col-md-5th-offset-2{
    margin-left: 40%;
  }
  .col-md-5th-offset-1{
    margin-left: 20%;
  }
  .col-md-5th-offset-0{
    margin-left: 0%;
  }
}

@media (min-width: 990px) and (max-width: 1045px ) {
  .about-us-info-image img {
    top: 150px; 
    left: -70px; 
  }
  .partner-logos a {
    padding-left: 0; 
  }
  .partners .content div:last-child:before,
  .partners .content div:last-child:after {
    background: none; 
  }
  .modal-body {
    margin: 0 10px; 
  }
  .modal-body p {
    font-size: 18px; 
  }
  #services_rq table {
    margin-bottom: 30px; 
    margin-left: -100;
    width: 900px;
    border-collapse: separate;
      border-spacing: 1px;
      opacity: 1;
  }
  .table-responsive{
    overflow-x: auto;
  }
  div#datatable_filter {
    margin-left: 0 !important;
  }
}

@media (min-width: 768px) {
  .modal-xl {
    width: 85%;
    max-width: 1200px;
  }
  .modal-lgx {
    width: 85%;
    max-width: 1000px;
  }
}

@media only screen and (max-width: 960px) {
  .rev {
    display: flex;
    flex-direction: column-reverse;
  }
}

@media (min-width: 768px) and (max-width: 991px ) {
  .banner-content {
    left: 42%; 
  }
  .about-us .wrap-info {
    width: 80%;
  }
  .about .wrap-info {
    padding-right: 80px; 
  }
  .references .wrap-info {
    padding-left: 80px; 
  }
  .partners p{
    padding-top: 15px; 
    padding-bottom: 15px; 
  }
  .info-news .info-title {
    right: 23px;
    left: 22px;
  }
  .info-news .carousel-inner .active.left {
    left: -33.3%;
  }
  .info-news .carousel-inner .next {
    left:  33.3%;
  }
  .info-news .carousel-inner .prev {
    left: -33.3%;
  }
  .info-news .active > div:first-child {
    display:block;
  }
  .info-news .active > div:first-child + div {
    display:block;
  }
  .info-news .active > div:last-child {
    display:none;
  }
  .modal-body {
    margin: 0 20px; 
  }
  .modal-body input {
    float: left; 
  }
  .modal-body p {
    font-size: 16px; 
  }
  #pop-upQuery .modal-body p {
    margin-bottom: 0; 
    text-align: left; 
  }
  .modal-body-step-3 .obqvena-stoinost {
    margin-top: 73px;
  }
  #pop-upQuery .modal-body {
    padding: 0; 
  }
  #pop-upQuery label {
    float: left
  }
  #pop-upQuery #zabelejka {
    width: 95%; 
    margin-left: 15px; 
    margin-right: 10px; 
  }
  #pop-upQuery .modal-footer {
    margin-top: 10px; 
  }
  #pop-upQuery .modal-steps-query {
    width: 100%; 
    text-align: center; 
  }
  #pop-upQuery .divider {
    display: none; 
  }
  .jumbotron .container {
    padding:  50px 100px 0 100px; 
  }
  #services_rq table {
    margin-bottom: 30px; 
    margin-left: -99px;
    width: 0;
    border-collapse: separate;
      border-spacing: 1px;
      opacity: 1;
  }
  .table-responsive{
    overflow-x: auto;
  }
  div#datatable_filter {
    margin-left: 0 !important;
  }
  .ret-track {
    display: none;
  }
  #change-form {
    margin-top: 5%;
  }
}

@media(min-width: 500px) {
  .right-menu-section {
    position: relative; 
  }
  #right-menu a {
    position: absolute;
    right: -260px;
    transition: 0.3s;
    padding: 15px;
    width: 320px;
    text-decoration: none;
    font-size: 20px;
    color: white;
    background-color: #f47c36; 
    border-bottom: 1px solid #f7a47d; 
    z-index: 2; 
    outline: none;
  }
  #right-menu a:hover {
    right: 0;
  }
  #right-menu a:visited {
    outline: none;
  }
  #right-menu a span {
    margin-left: 60px; 
    font: 21px 'robotomedium', sans-serif;
    color: #fff; 
  }
  #new-request-option {
    top: 40px;
  }
  #trace-option {
    top: 100px;
  }
  #redirect-option {
    top: 160px;
  }
  #calculate-option {
    top: 220px;
  }
  .right-menu-section #report-option {
    top: 280px;
    background-color: #f47c36; 
    border: none; 
  }

  /********************************/
  /*          Firefox debug        */
  /********************************/
  @-moz-document url-prefix() { 
    #new-request-option {
      top: 39px;
    }
    #redirect-option {
      top: 161px;
    }
    #calculate-option {
      top: 222px;
    }
    .right-menu-section #report-option {
      top: 283px;
    }
  }
}

@media(max-width: 499px) {
  .fix-nav {
    position:fixed; 
    top:0; 
    left:0; 
    z-index:1000; 
    width:100%;
  }
  #right-menu a, .right-menu-section #report-option {
    width: 20%; 
    padding: 10px 0px; 
    background-color: #f47c36; 
    border-right: 2px solid #f7a47d;
    background-position: center 10px;
    display: inline-block; 
    margin-right: -4px;
    outline: none;
  }
  #right-menu a span {
    width: 76%; 
    padding-top: 44px;  
    display: block;   
    text-align: center; 
    margin: 0 auto;  
    font: 9px 'robotoregular', sans-serif;
    color: #fff; 
  }
  .right-menu-section #report-option span {
    width: 50px;  
  }
  .banner-content {
    left: 50%;
    top: 50%; 
  }
  .img-rounded {
    width: 300px;
    height: 300px;
  }
  .banner-content p {
    font-size: 52px; 
  }
  .banner-content h1 {
    font-size: 52px; 
  }
  #loading>div{
    width: 40%;
    margin-left: -220px;
  }
  #services_rq table {
    margin-bottom: 30px; 
    margin-left: 0;
    width: 0;
    border-collapse: separate;
      border-spacing: 1px;
      opacity: 1;
  }
  .table-responsive{
    overflow-x: auto;
  }
  div#datatable_filter {
    display: none;
  }
  #services_rq .table {
    margin-left: 0;
  }
  .ret-track {
    display: none;
  }
  #change-form {
    margin-top: 5%;
  }
}

@media (max-width: 690px) {
  #jay-signature-pad {
    width: 300px !important;
    height: 270px !important;
  }
  .presentation {
    width: 100%;
  }
  .partners p {
    font-size: 34px; 
    text-align: center;
    line-height: 1.2;  
  }
  .partners .content div:last-child:before,
  .partners .content div:last-child:after {
    background: none; 
  }
  .partner-logos a {
    margin-top: 20px; 
    margin-bottom: 20px; 
  }
  #services_rq table {
    margin-bottom: 30px; 
    margin-left: 0;
    width: 0;
    border-collapse: separate;
      border-spacing: 1px;
      opacity: 1;
  }
  .table-responsive{
    overflow-x: auto;
  }
  .ref {
    margin-top: 3% !important;
  }
  div#datatable_filter {
    display: none;
  }
  .ret-track {
    display: none;
  }
  #change-form {
    margin-top: 10%;
  }

}

@media (min-width: 768px) {
  .col-sm-5th-1, .col-sm-5th-2, .col-sm-5th-3, .col-sm-5th-4{
    float: left;
  }
  .col-sm-5th-5 {
    float: left;
    width: 100%;
  }
  .col-sm-5th-4{
    width: 80%;
  }
  .col-sm-5th-3 {
    width: 60%;
  }
  .col-sm-5th-2{
    width: 40%;
  }
  .col-sm-5th-1{
    width: 20%;
  }
  .col-sm-5th-pull-5{
    right: 100%;
  }
  .col-sm-5th-pull-4 {
    right: 80%;
  }
  .col-sm-5th-pull-3 {
    right: 60%;
  }
  .col-sm-5th-pull-2{
    right: 40%;
  }
  .col-sm-5th-pull-1 {
    right: 20%;
  }
  .col-sm-5th-pull-0{
    right: auto;
  }
  .col-sm-5th-push-5{
    left: 100%;
  }
  .col-sm-5th-push-4{
    left: 80%;
  }
  .col-sm-5th-push-3{
    left: 60%;
  }
  .col-sm-5th-push-2{
    left: 40%;
  }
  .col-sm-5th-push-1{
    left: 20%;
  }
  .col-sm-5th-push-0{
    left: auto;
  }
  .col-sm-5th-offset-5{
    margin-left: 100%;
  }
  .col-sm-5th-offset-4{
    margin-left: 80%;
  }
  .col-sm-5th-offset-3{
    margin-left: 60%;
  }
  .col-sm-5th-offset-2{
    margin-left: 40%;
  }
  .col-sm-5th-offset-1{
    margin-left: 20%;
  }
  .col-sm-5th-offset-0{
    margin-left: 0%;
  }
}

@media(min-width: 498px) and (max-width: 768px) {
  .banner-content {
    left: 45%; 
  }
  .banner-content p {
    font-size: 60px; 
  }
  .ref {
    margin-top: 3% !important;
  }
  div#datatable_filter {
    display: none;
  }
  .ret-track {
    display: none;
  }
  #change-form {
    margin-top: 10%;
  }
}

@media (max-width: 767px) {
  #services_rq h1 {
    left: 0;
  }
  #menu .navbar-brand {
    float: none;
    margin-top: 5px;
  }
  #menu .navbar-brand img {
    height: 40px;
  }
  .about-us-info {
    background: url(images/about-us-background-mobile.jpg) no-repeat center center;
  }
  .references-info { 
    background: url(images/references-background-mobile.jpg) no-repeat center center;
  }
  .about-us .about,
  .about-us .about-us-info-image,
  .about-us .references {
    width: 100%; 
    display: block; 
  }
  .about-us .wrap-info {
    width: 93%; 
    padding-left: 15px; 
    padding-right: 15px; 
  }
  .about-us .wrap-info h3 {
    padding-left: 0; 
  }
  .about-us .about-us-info {
    margin-bottom: 250px; 
  }
  .about-us .about-us-info-image img {
    width: 80%; 
    top: -20px; 
    left: 10%; 
  }
  .references-info .wrap-info {
    padding-right: 15px; 
  }
  .references-info-image {
    display: none; 
  }
  .partners p {
    padding-top: 15px; 
    padding-bottom: 15px; 
  }
  .info-news .info-title {
    text-align: center; 
  }
  .social-media-items {
    width: 100%; 
    float: none; 
    margin: 0 auto; 
  }
  #logo-white {
    margin: 0 auto; 
    padding-top: 15px;
    padding-bottom: 20px; 
  }
  .social-media .list-group-horizontal .list-group-item, 
  .social-media .list-group-horizontal .list-group-item:first-child {
    border: none; 
  }
  .info-news .carousel-inner .active.left {
    left: -100%;
  }
  .info-news .carousel-inner .next {
    left:  100%;
  }
  .info-news .carousel-inner .prev {
    left: -100%;
  }
  .info-news .active > div {
    display:none;
  }
  .info-news .active > div:first-child {
    display:block;
  }
  .modal-body {
    margin: 0 10px; 
  }
  .modal-body p, .modal-body input {
    float: left; 
    margin-bottom: 0; 
  }
  .modal-content .row div {
    margin-bottom: 0; 
  }
  .close-dialog {
    top: 36px; 
    right: 30px; 
  }
  .modal-body p {
    font-size: 16px; 
  }
  .section-radio .row label{
    float: right; 
  }
  .modal-title {
    padding: 20px 0 20px 60px;
    margin-left: 25px; 
    font-size: 18px; 
  }
  .modal-content h2 {
    font-size: 18px; 
  }
  .modal-footer {
    margin-left: 20px; 
    margin-right: 20px; 
  }
  .modal-footer p, .modal-content input[name="total-price"] {
    font-size: 20px; 
  }
  .submit-btn {
    font-size: 20px; 
  }
  .modal-body-step-3 .obqvena-stoinost {
    margin-top: 78px;
  }
  #pop-upQuery .modal-steps-query {
    width: 100%; 
    text-align: center; 
  }
  #pop-upQuery .modal-body {
    padding: 0; 
  }
  #pop-upQuery h2 {
    width: 270px; 
  }
  #pop-upQuery label {
    float: left
  }
  #pop-upQuery #zabelejka {
    width: 95%; 
    margin-left: 15px; 
    margin-right: 10px; 
  }
  #pop-upQuery .modal-footer {
    margin-top: 10px; 
  }
  .jumbotron .container {
    padding:  50px 40px 0 40px;
  }
  #services_rq table {
    margin-bottom: 30px;
    margin-left: 0;
    width: 0; 
    border-collapse: separate;
      border-spacing: 1px;
      opacity: 1;
  }
  .table-responsive{
    overflow-x: auto;
  }
  .ref {
    margin-top: 3% !important;
  }
  div#datatable_filter {
    display: none;
  }
  #container-title {
    display: none;
  }
  .ret-track {
    display: none;
  }
  #change-form {
    margin-top: 10%;
  }
}

@media (max-width: 367px) {
  .presentation {
    width: 100%;
  }
  #services_rq h1 {
    left: 0;
  }
  #username, #password {
    width: 70%;
  }
  #menu .navbar-brand {
    margin-right: 0; 
    float: none;
  }
  #menu .navbar-brand img{
    height: 29px;
  }
  #right-menu a, .right-menu-section #report-option {
    padding: 31px 0;  
    vertical-align: top;
    background-size: 47px 35px;
  }
  #right-menu a span {
    display: none;  
  }
  .banner-content {
    left: 50%; 
  }
  .banner-content p {
    font-size: 32px; 
  }
  .banner-content a {
    font-size: 20px; 
  }
  #operations .row div {
    width: 100%; 
    display: block; 
  }
  #operations a {
    font-size: 20px; 
  }
  #operations img {
    height: 70px; 
  }
  .about-us .about-us-info {
    margin-bottom: 100px; 
  }
  .about-us .about-us-info-image img {
    top: -10px; 
  }
  .info-news .info-title {
    right: 0px;
    left: 0px;
  }
  .social-media-items a {
    padding: 10px 10px; 
  }
  .modal-body {
    margin: 0 5px; 
  }
  .modal-title {
    margin-left: 0; 
    padding-top: 35px; 
  }
  .modal-footer {
    padding: 0; 
    margin-left: 5px; 
    margin-right: 5px; 
  }
  .modal-footer p, .modal-content input[name="total-price"] {
    font-size: 18px; 
  }
  .modal-footer input {
    width: 140px;  
    padding: 0; 
  }
  .submit-btn {
    margin-right: 15px;  
    padding: 10px 20px; 
    font-size: 18px; 
  }
  .close-dialog {
    top: 10px; 
    left: 130px; 
  }
  #pop-upQuery .modal-body {
    padding: 0; 
  }
  #pop-upQuery .modal-body-step-3 {
    margin-left: 0px; 
    margin-right: 0px; 
  }
  #pop-upQuery .modal-body-step-3 p {
    text-align: left; 
  }
  #pop-upQuery .modal-body label {
    float: left;
    text-align: left; 
  }
  #pop-upQuery .step {
    font-size: 15px; 
    padding: 3px 9px; 
  }
  #pop-upQuery .modal-steps-query {
    padding-right: 15px; 
  }
  #pop-upQuery .modal-steps-query i {
    width: 7px; 
    height: 7px; 
  }
  #pop-upQuery h2 {
    width: 145px; 
  }
  #pop-upQuery #zabelejka {
    width: 90%; 
    margin-left: 15px; 
    margin-right: 10px; 
  }
  #pop-upQuery .modal-footer {
    margin-top: 10px; 
  }
  #pop-upQuery .submit-query-btn {
    margin-left: 0; 
  }
  .jumbotron .container {
    padding:  30px 15px 0 15px;
  }
  #services h1 {
    left: 10px; 
    font-size: 20px; 
  }
  #services_rq table {
    margin-bottom: 30px;
    margin-left: 0;
    width: 0;
    border-collapse: separate;
      border-spacing: 1px;
      opacity: 1;
  }
  .table-responsive{
    overflow-x: auto;
  }
  .ref {
    margin-top: 3% !important;
  }
  div#datatable_filter {
    display: none;
  }
  #container-title {
    display: none;
  }
  .ret-track {
    display: none;
  }
  #change-form {
    margin-top: 10%;
  }
}