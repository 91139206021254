/*-------------------    modal pop-up query
*/
#pop-upQuery .modal-body {
	padding-top: 0; 
	margin: 0 20px; 
}

#pop-upQuery .modal-title {
	background: url(images/query-modal-title-icon.png) no-repeat center left;
	display: inline-block; 
	color: #f47c36;
	font-size: 26px;
}

#pop-upQuery .modal-steps-query {
	padding-right: 50px; 
}

#pop-upQuery .modal-steps-query i,
#pop-upQuery .step {
	background-color: #b7b7b7; /* for IE  */
	background-color: rgb(183, 183, 183, 1); 
}

#pop-upQuery .modal-steps-query i {
	width: 13px; 
	height: 13px; 
	border-radius: 50%;
	display: inline-block; 
}

#pop-upQuery .step {
	padding: 3px 13px; 
	font: 25px 'robotobold', sans-serif;
	color: #fffefe; 
	border-radius: 50%;
	display: inline-block; 
}

#pop-upQuery .step-active {
	background-color: #f47c36; /* for IE  */
	background-color: rgb(242, 98, 39, 1); 
}

#pop-upQuery h2 {
	padding: 10px 0px; 
	width: 323px; 
	margin: 0 auto; 
	color: #fffefe; 
	background-color: #f47c36; 
	border-radius: 9px;
	text-transform: uppercase; 
	display: inline-block; 	
}

.modal-body-step-1 h2,
.modal-body-step-2 h2 {
	margin-bottom: 25px; 
}

#pop-upQuery .modal-content input {
	width: 100%; 
	padding: 5px; 
	background-color: #fff; 
}
								
#pop-upQuery input[name="weight"], 
#pop-upQuery input[name="cod"], 
#pop-upQuery input[name="insurance"] {
	width: 70%; 
}

#pop-upQuery input[name="query-total-price"] {
	font-size: 25px; 
	width: 150px; 
	background-color: transparent;
	border: none; 
}
.modal-footer input[name="query-total-price"]:-ms-input-placeholder,
.modal-footer input[name="query-total-price"]::-ms-input-placeholder { /* Microsoft Edge */ 
  color: #373737;
}

.modal-footer input[name="query-total-price"]::placeholder {
  color: #373737;
  opacity: 1; /* Firefox */
}

#pop-upQuery textarea {
	width: 100%;
	border-radius: 4px;
	border: 1px solid #959595;
}

#pop-upQuery .modal-footer {
	padding: 0; 
	padding-right: 15px; 
	margin-left: 20px; 
	margin-right: 20px; 
	margin-top: 0; 
}

#pop-upQuery .modal-footer button {
	padding: 7px 55px; 
	text-transform: uppercase; 
}

.modal-body-step-2,
.modal-body-step-3,
.price-section,
.submit-query-btn {
	display: none; 
}

.modal-body-step-3 .row {
	margin: 0 3px; 
}

#pop-upQuery .content-section {
	padding-bottom: 0; 
}

#pop-upQuery .row-section {
	padding-bottom: 5px; 
	margin-bottom: 10px; 
	border-bottom: 2px solid #b4b4b4; 
}

.modal-body-step-3 .weight,
.modal-body-step-3 input[name="width"], 
.modal-body-step-3 input[name="depth"], 
.modal-body-step-3 input[name="height"] {
	display: inline-block; 
}

#pop-upQuery input[name="width"], 
#pop-upQuery input[name="depth"], 
#pop-upQuery input[name="height"] {
	width: 20%;  
}

.price-section input {
	display: inline-block; 
}

#pop-upQuery ::-webkit-input-placeholder {
   text-align: center;
}

#pop-upQuery :-moz-placeholder { /* Firefox 18- */
   text-align: center;  
}

#pop-upQuery ::-moz-placeholder {  /* Firefox 19+ */
   text-align: center;  
}

#pop-upQuery :-ms-input-placeholder {  
   text-align: center; 
}

.textarea-coll {
	position: relative;
}

.modal-body-step-3 {
	padding-bottom: 0;
}

.modal-body-step-3 #zabelejka {
	position: absolute;
	top: 0; 
	left: 0; 
}

.modal-body-step-3 label p,
.modal-body-step-3 .sume-col p, 
.modal-body-step-3 .measure-col p, 
.modal-body-step-3 .sume-col input {
	display: inline-block; 
}

.modal-body-step-3 .sume-col p, 
.modal-body-step-3 .measure-col p,
.modal-body-step-3 .weight,
.modal-body-step-3 .currency {
	font-family: 'robotoregular', sans-serif;
}

.selectParent {
	width: 100%;
}

.selectParent select { 
	display: block;
	width: 100%;
	padding: 3px 25px 3px 15px; 
	border: 1px solid #959595; 
	border-radius: 4px;
	background: #fff url(images/arrow-dropdown.png) no-repeat right center ; 
	appearance: none; 
	-webkit-appearance: none;
	-moz-appearance: none; 
	font: 16px 'robotolight_italic', sans-serif;
}

select::-ms-expand { /* for IE and Edge */ 
	display: none; 
}

#pop-upQuery .divider {
	font-family: 'robotolight', sans-serif;
}

.error {
	color: #ff8c12 !important;
}
