.sameday-lite__container {
    background-color: #bcbec0;
    padding: 1rem 3.5rem 3rem 3.5rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5rem;
}

.sameday-lite__container .sameday-lite__title {
    font-family: 'Rubik', sans-serif;
    font-style: italic;
    font-weight: 900;
    font-size: 4rem;
    color: #d16828;
    margin-bottom: -18px;
}

.sameday-lite__container .btn {
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
    font-weight: 600;
    color: white;
    border: none;
    border-radius: 0;
    letter-spacing: 0.5px;
    padding-left: 8px;
    padding-right: 8px;
    margin-top: -30px;
}

.sameday-lite__container .btn-make-request {
    background-color: #e9722a;
}

.sameday-lite__container .btn-learn-more {
    background-color: #52555a;
}

.sameday-lite__container .sameday-lite__list {
    list-style-type: none;
    margin-top: 7rem;
    font-size: 1.5rem;
}

.sameday-lite__container .sameday-lite__list li {
    font: 16px 'robotoregular', sans-serif;
    margin-bottom: 2px;
}

.sameday-lite__container .sameday-lite__list li:before {
    content: '';
    display: inline-block;
    vertical-align: baseline;
    margin-right: 12px;
    transform: rotate(45deg);
    height: 13px;
    width: 10px;
    border-bottom: solid 3.5px #f48729;
    border-right: solid 3.5px #f48729;
}

.sameday-lite__container .sameday-lite__image--container {
    position: relative;
    width: 300px;
    margin-left: auto;
    margin-right: auto;
}

.sameday-lite__main-image {
    height: 250px;
}

.sameday-lite__stamp-image {
    position: absolute;
    height: 55px;
    top: -28px;
    left: 73px;
}

.sameday-lite__container .d-mobile {
    display: none;
}

@media (min-width: 320px) and (max-width: 991px) {
    .sameday-lite__container {
        padding: 1rem 3rem 1.5rem 3rem;
    }
    .sameday-lite__container .d-mobile {
        display: block;
    }
    .sameday-lite__container .btn-learn-more.btn-phone {
        background-color: #bcbec0;
        color: #52555a;
        padding-left: 0;
        padding-right: 0;
        letter-spacing: 0;
    }
    .sameday-lite__container .btn-make-request {
        margin-bottom: 8px;
    }
    .sameday-lite__container .btn-learn-more.btn-phone,
    .sameday-lite__container .btn-make-request {
        margin-top: 0;
    }
    .sameday-lite__container .btn-learn-more.btn-email {
        display: none;
    }
    .sameday-lite__container .sameday-lite__title {
        text-align: center;
        margin-bottom: 5px;
        font-size: 3.5rem;
    }
    .sameday-lite__container .sameday-lite__image--container {
        text-align: center;
    }
    .sameday-lite__main-image {
        height: 240px;
    }
    .sameday-lite__stamp-image {
        top: 14px;
        left: 3px;
    }
    .sameday-lite__container .sameday-lite__list {
        margin-top: -1rem;
        margin-bottom: 2rem;
    }
}

/* Utils */
.text-align-center {
    text-align: center;
}

.sameday-lite__container .no-gutters {
    padding-left: 0;
    padding-right: 0;;
}

.link__dark {
    color: black !important;
}

/* Fix */
.jumbotron .sameday-lite__container .row {
    margin-bottom: 0;
}

#services_usl .sameday-lite__container .sameday-lite__list {
    padding-left: 3px;
}