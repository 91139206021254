
/**
 * Prices table
 */

#old-c {
	margin-top: 3%;
}

.old-f {
	margin-left: 9%;
}

.logistics-prices {
	width: 80%;
	margin: 0 auto;
	border-collapse: separate;
	font-size: 13px;
}

.logistics-prices tr:nth-child(even) {
	background: #e6e6e6;
}

.logistics-prices tr:nth-child(odd) {
	background: #f3f3f3;
}

.logistics-prices th {
	background: orange;
	color: #fff;
	height: 30px;
	vertical-align: middle;
	text-align: center;
	border: solid 1px #fff;
	font-weight: bold;
}

.logistics-prices td {
	color: #333;
	height: 30px;
	vertical-align: middle;
	text-align: center;
	border: solid 1px #fff;
	font-weight: bold;
	padding: 0 10px;
}

.logistics-prices td.first-cell {
	text-align: left;
	font-weight: normal;
}

/**
 * Form
 */
 
#logistics-form  h2 {
	font-size: 14px;
	color: #000;
	height: 24px;
	line-height: 24px;
	font-weight: bold;
}

#logistics-form  .no-box {
	font-size: 14px;
	color: #000;
	padding: 2px 0px 4px;
	height: 24px;
	line-height: 24px;
	font-weight: bold;
	border-bottom: solid 1px #000;
}

#logistics-form  p {
	min-height: 24px;
}

.logistics-left,
.logistics-right {
	width: 322px;
	float: left;
	margin-top: 20px;
}

.column-left,
.column-right{
	width: 322px;
	float: left;
	margin: 10px 30px 10px 0px;
	border-top: solid 1px #000;
}

.column-left p span, .column-right p span {
	display: inline-block;
	width: 180px;
}

.logistics-center {
	width: 322px;
	margin: 20px 10px 30px 10px;
}

.logistics-small-left, .logistics-small-right {
	float: left;
	width: 322px;
}

.logistics-wrapper {
	float: left;
	overflow: hidden;
	margin-top: 10px;
}

.logistics-small-left {
	margin-right: 20px;
}

.logistics-left {
	margin-right: 20px;
}

.logistics-full-wrap {
	width: 934px;
	float: left;
	padding: 20px 0;
	background: #f8f8f8;
	border: solid 1px #ccc;
	/*background: url("../images/form-full.png") repeat-x;*/
	margin-top: 20px;
}

.logistics-full {
	width: 914px;
	float: left;
	margin: 0px 0 0 20px;
}

.logistics-full p {
	width: 437px;
	margin-right: 20px;
	float: left;
	min-height: 24px;
}

.logistics-full-left,
.logistics-full-right {
	width: 427px;
	float: left;
	margin: 0px 20px 0;
}

.box {
	padding: 17px 20px 20px 20px;
	width: 350px;
	background: #efefef;
}

.box input,
.box select,
.box textarea {
	background: #fff !important;
}

.box label {
	width: 112px !important;
}

.triplet {
	width: 954px;
	overflow: hidden;
	float: left;
	margin-top: 30px;
}

.triplet-section {
	width: 298px;
	background: #ccc;
	float: left;
	margin-right: 20px;
}

#logistics-form .full-width {
	width: 894px !important;
}

#logistics-form p {
	margin-top: 5px;
	margin-bottom: 0;
}

#logistics-form .marginleft {
	margin-left: 135px;
}

#logistics-form h2 {
	margin: -4px 0 20px;
}

#logistics-form p:after {
	clear:both;
    content:".";
    display:block;
    height:0;
    visibility:hidden;
}

#logistics-form label {
    color: #666666;
    display: inline-block;
    font-size: 11px;
	width: 140px;
	float: left;
	height: 22px;
	vertical-align: middle;
}

#logistics-form .radio {
    width: 70px;
}

#logistics-form input[type=radio] {
    margin: 3px 3px 0 3px;
}

#logistics-form input[type=email],
#logistics-form input[type=text],
#logistics-form input[type=password] {
    float: left;
	display: inline-block;
	width: 180px;
	height: 18px;
	padding: 2px 0;
	border: solid 1px #aaa;
	background: #efefef;
}

#logistics-form .large {
	width: 168px !important;
}

#logistics-form select.large {
	width: 170px !important;
}

#logistics-form select {
  float: left;
	display: inline-block;
	width: 180px;
	padding: 2px 2px 2px 0;
	border: solid 1px #aaa;
	background: #efefef;
	font-size: 13px;
}

#logistics-form textarea{
  float: left;
	display: inline-block;
	width: 176px;
	padding: 2px 2px;
	border: solid 1px #aaa;
	background: #efefef;
	font-family: Arial, Helvetica, sans-serif;
	font-size: 13px;
}

#logistics-form .small{
    height: 43px !important;
}

#logistics-form input[type=email]:focus,
#logistics-form input[type=text]:focus,
#logistics-form input[type=password]:focus,
#logistics-form select:focus,
#logistics-form textarea:focus {
	background: #fff;
	-webkit-box-shadow: 0px 0px 5px rgba(50, 50, 50, 0.5);
	-moz-box-shadow:    0px 0px 5px rgba(50, 50, 50, 0.5);
	box-shadow:         0px 0px 5px rgba(50, 50, 50, 0.5);
}

#dimentions input[type=text] {
	width: 37px;
	margin-right: 3px;
}

#dimentions span {
	display: inline-block;
	float: left;
	margin-right: 3px;
	color: #666666;
    font-size: 11px;
}

#contract-number {
	display: none;
}

.full-submit {
	width: 936px !important;
}

#bill label {
	width: 85px !important;
}

#bill select {
	width: 100px !important;
}

#additional-info textarea {
	width: 176px;
	height: 18px;
}

#logistics-form  label.error {
    margin-left: 140px;
	display: block;
	float: left;
	width: 180px;
	overflow: hidden;
	height: auto !important;
	line-height: 14px !important;
	color: #ed5408;
}

#logistics-form  .box label.error {
	width: 170px !important;
	margin-left: 112px !important;
}

#logistics-form .form-submit {
	float: left;
	margin: 24px 0 28px;
	text-align: center;
	width: 640px;
}


#request-price {
	float: right;
	margin-right: 176px;
	color: #333;
}

#request-price #price {
	color: #ed5408;
}

#prices {
	width: 100%;
}

#prices input[type="button"] {
	font-size: 11px;
}

#prices input[type="text"] {
	width: 80px;
}

#prices p {
	font-size: 11px;
}

/* Anketa */

#wpcf7-f95-p93-o1 .field-group,
.wpcf7-form .field-group {
	margin-bottom: 10px;
	height: auto;
}

#wpcf7-f95-p93-o1 .hidden-group,
.wpcf7-form .hidden-group {
	display: none;
}

#wpcf7-f95-p93-o1 .field-group:after,
.wpcf7-form .field-group:after {
    clear: both;
    content: ".";
    display: block;
    height: 0px;
    visibility: hidden;
}

#wpcf7-f95-p93-o1 hr,
.wpcf7-form hr {
    border: 0px none;
    height: 1px;
    background: none repeat scroll 0% 0% #EEE;
    margin: 20px 0px 20px;
}

#wpcf7-f95-p93-o1 label,
.wpcf7-form label {
    color: #666;
    display: block;
    font-size: 11px;
    width: auto;
    margin-bottom: 2px;
    vertical-align: middle;
	float: left;
	width: 160px;
	margin-right: 10px;
	line-height: 22px;
}

#wpcf7-f95-p93-o1 label.two-lines,
.wpcf7-form label.two-lines {
    margin-top: -3px;
	line-height: 12px;
}

#wpcf7-f95-p93-o1 label.two-lines span,
.wpcf7-form label.two-lines span {
    font-size: 10px;
	font-style: italic;
	color: #888;
}

#wpcf7-f95-p93-o1 .heading-label,
.wpcf7-form .heading-label {
    width: 445px;
	height: auto;
	display: block;
    margin: 0 0 5px 0;
    font-size: 13px;
	line-height: 17px;
    color: #000;
    font-weight: bold;
	float: none;
}

#wpcf7-f95-p93-o1 input[type=text],
.wpcf7-form input[type=text],
#wpcf7-f95-p93-o1 input[type=date],
.wpcf7-form input[type=date],
#wpcf7-f95-p93-o1 input[type=tel],
.wpcf7-form input[type=tel],
#wpcf7-f95-p93-o1 input[type=email],
.wpcf7-form input[type=email],
#wpcf7-f95-p93-o1 input[type=number],
.wpcf7-form input[type=number] {
	display: block;
	height: 18px;
	padding: 2px 0;
	border: solid 1px #aaa;
	background: #efefef;
	font-family: Verdana, Helvetica Neue, Helvetica, Arial, Verdana, sans-serif;
	font-size: 13px;
	float: left;
	margin-right: -1px;
}

#wpcf7-f95-p93-o1 input[type=text],
.wpcf7-form input[type=text],
#wpcf7-f95-p93-o1 input[type=date],
.wpcf7-form input[type=date],
#wpcf7-f95-p93-o1 input[type=tel],
.wpcf7-form input[type=tel],
#wpcf7-f95-p93-o1 input[type=email],
.wpcf7-form input[type=email] {
	width: 200px;
}

#wpcf7-f95-p93-o1 input[type=number],
.wpcf7-form input[type=number] {
	width: 60px;
}

#wpcf7-f95-p93-o1 input[type=text]:focus,
.wpcf7-form input[type=text]:focus,
#wpcf7-f95-p93-o1 input[type=date]:focus,
.wpcf7-form input[type=date]:focus,
#wpcf7-f95-p93-o1 input[type=tel]:focus,
.wpcf7-form input[type=tel]:focus,
#wpcf7-f95-p93-o1 input[type=email]:focus,
.wpcf7-form input[type=email]:focus,
#wpcf7-f95-p93-o1 input[type=number]:focus,
.wpcf7-form input[type=number]:focus,
#wpcf7-f95-p93-o1 textarea:focus,
.wpcf7-form textarea:focus {
    background: none repeat scroll 0% 0% #FFF;
    box-shadow: 0px 0px 5px rgba(50, 50, 50, 0.5);
}

#wpcf7-f95-p93-o1 textarea,
.wpcf7-form textarea {
    display: inline-block;
	height: 100px;
    padding: 2px;
    border: 1px solid #AAA;
    background: none repeat scroll 0% 0% #EFEFEF;
    font-family: Arial,Helvetica,sans-serif;
    font-size: 13px;
	float: left;
	margin-right: -1px;
	width: 445px;
}

#wpcf7-f95-p93-o1 .checkboxes,
.wpcf7-form .checkboxes {
    border: 0;
}

#wpcf7-f95-p93-o1 .wpcf7-form-control-wrap,
.wpcf7-form .wpcf7-form-control-wrap {
	display: block;
}

#wpcf7-f95-p93-o1 .wpcf7-list-item,
.wpcf7-form .wpcf7-list-item {
    float: none !important;
    width: auto;
    display: block;
	font-size: 11px;
    line-height: 14px;
	height: 22px;
	color: #666;
}

#wpcf7-f95-p93-o1 .wpcf7-list-item  input[type="checkbox"],
.wpcf7-form .wpcf7-list-item  input[type="checkbox"] {
	float: left;
}

.wpcf7-form .wpcf7-checkbox {
	margin-bottom: 5px;
	display: block;
}

.wpcf7-form .wpcf7-list-item {
	margin-top: 10px;
	height: auto !important;
}

#form-submit input[type=submit],
.wpcf7-form input[type=submit] {
    width: 122px;
    height: 36px;
    background: url("../images/send.png") repeat-x scroll left top transparent;
    border: 0px none;
    cursor: pointer;
}

#form-submit input[type=submit]:hover {
    background: url("../images/send_h.png") repeat-x scroll left top transparent;
}

#wpcf7-f95-p93-o1 .wpcf7-not-valid-tip,
.wpcf7-form .wpcf7-not-valid-tip {
    display: inline-block;
	float: left;
    font-size: 12px;
    color: #ED5408;
    margin: 0;
    line-height: 16px;
    background: none repeat scroll 0% 0% #FCF1A8;
    padding: 3px 5px 3px;
    border: 1px solid #aaa;
}

#wpcf7-f95-p93-o1 .wpcf7-response-output,
.wpcf7-form .wpcf7-response-output {
	margin: 10px 0 0;
	border: 0;
	padding: 7px 16px 10px;
	font-size: 13px;
	line-height: 18px;
}

#wpcf7-f95-p93-o1 .wpcf7-validation-errors,
.wpcf7-form .wpcf7-validation-errors {
	background: #ff0000;
	color: #fff;
}

#wpcf7-f95-p93-o1 .wpcf7-mail-sent-ok,
.wpcf7-form .wpcf7-mail-sent-ok {
	background: green;
	color: #fff;
}

#ui-datepicker-div {
	font-size: 12px;
}

#courier-box  h2 {
	font-size: 14px;
	color: #000;
	height: 24px;
	line-height: 24px;
	font-weight: bold;
}

#courier-box  .no-box {
	font-size: 14px;
	color: #000;
	padding: 2px 0px 4px;
	height: 24px;
	line-height: 24px;
	font-weight: bold;
	border-bottom: solid 1px #000;
}

#courier-box  p {
	min-height: 24px;
}


#courier-box .full-width {
	width: 894px !important;
}

#courier-box p {
	margin-top: 5px;
	margin-bottom: 0;
}

#courier-box .marginleft {
	margin-left: 135px;
}

#courier-box h2 {
	margin: -4px 0 20px;
}

#courier-box p:after {
	clear:both;
    content:".";
    display:block;
    height:0;
    visibility:hidden;
}

#courier-box label {
    color: #666666;
    display: inline-block;
    font-size: 11px;
	width: 140px;
	float: left;
	height: 22px;
	vertical-align: middle;
}

#courier-box .radio {
    width: 70px;
}

#courier-box input[type=radio] {
    margin: 3px 3px 0 3px;
}

#courier-box input[type=email],
#courier-box input[type=text],
#courier-box input[type=password] {
    float: left;
	display: inline-block;
	width: 180px;
	height: 18px;
	padding: 2px 0;
	border: solid 1px #aaa;
	background: #efefef;
}

#courier-box .large {
	width: 168px !important;
}

#courier-box select.large {
	width: 170px !important;
}

#courier-box select {
  float: left;
	display: inline-block;
	width: 180px;
	padding: 2px 2px 2px 0;
	border: solid 1px #aaa;
	background: #efefef;
	font-size: 13px;
}

#courier-box textarea{
  float: left;
	display: inline-block;
	width: 176px;
	padding: 2px 2px;
	border: solid 1px #aaa;
	background: #efefef;
	font-family: Arial, Helvetica, sans-serif;
	font-size: 13px;
}

#courier-box .small{
    height: 43px !important;
}

#courier-box input[type=email]:focus,
#courier-box input[type=text]:focus,
#courier-box input[type=password]:focus,
#courier-box select:focus,
#courier-box textarea:focus {
	background: #fff;
	-webkit-box-shadow: 0px 0px 5px rgba(50, 50, 50, 0.5);
	-moz-box-shadow:    0px 0px 5px rgba(50, 50, 50, 0.5);
	box-shadow:         0px 0px 5px rgba(50, 50, 50, 0.5);
}

/* ==========================================================================
   Media Queries
   ========================================================================== */
