/*-------------------    modal pop-up calculate
*/
#pop-upCalculate p {display: inline-block; }

.modal-content {
  border: 6px solid #d25623; 
  border-radius: 55px;
  background-color:  #e8e8e8; /* for IE  */
  background-color: rgb(232, 232, 232, .86);
  font-family: 'robotomedium', sans-serif;
  color: #373737;
}

.modal-header {
  border: none; 
}

.modal-body {
  margin: 0 150px;
}

.modal-content .content-section {
  padding-top: 20px;
  padding-bottom: 20px; 
}

.modal-content .content-section:not(:last-child) {
  border-bottom: 2px solid #959595; 
}

.modal-content .row div {
  margin-top: 5px; 
  margin-bottom: 5px; 
}

.modal-title {
  margin-top: 10px; 
  margin-left: 45px; 
  padding: 10px 0 10px 60px; 
  font-size: 25px;
  color: #f47c36; 
  text-transform: uppercase; 
  background: url(images/query-modal-title-icon.png) no-repeat center left;
}

.modal-content label {
  padding-left: 0; 
}

.modal-content h2 {
  margin-top: 0; 
  font-size: 25px;
}

.modal-content p {
  font-size: 20px; 
  color: #373737;
}

.modal-content input {
  padding: 5px 15px; 
  font-size: 14px; 
  background-color: transparent; 
  color: #373737;
  border: none; 
  border: 1px solid #959595; 
  outline: none; 
  border-radius: 4px;
}

#pop-upCalculate .modal-content input {
  width: 100%;
}

#pop-upCalculate .modal-content #calc-cod {
  width: 117px !important;
}

.modal-content input[name="sender_town"],
.modal-content input[name="date-send"],
.modal-content input[name="date-receive"],
.modal-content input[name="recipient_town"],
.modal-content input[name="cod"],
.modal-content input[name="insurance"],
.modal-content input[name="fragile"],
.modal-content input[name="service"],
.modal-content input[name="package_count"],
.modal-content input[name="weight"],
.modal-content input[name="receipt"] {
  background-color: #fff; 
}

.modal-content input[name="sender_town"],
.modal-content input[name="recipient_town"],
.modal-content input[name="area-sender"],
.modal-content input[name="area-receiver"]{
  width: 100%; 
}

.modal-content input[name="fragile"] {
  width: 30px; 
}

.modal-content input[type="checkbox"] {
  width: 32px; 
  background-color: #fff;
}

.modal-footer {
  margin: 10px 80px 20px 80px;
  border-top: none; 
}

.modal-footer p, 
.modal-content input[name="total-price"] {
  font-size: 25px; 
}

.modal-footer input[name="total-price"]::placeholder {
  color: #373737;
  opacity: 1; /* Firefox */
}

.modal-footer input[name="total-price"]:-ms-input-placeholder, /* Internet Explorer 10-11 */
.modal-footer input[name="total-price"]::-ms-input-placeholder { /* Microsoft Edge */ 
  color: #373737;
}

.modal-content input[name="total-price"] {
  width: 150px; 
  border: none; 
}

.submit-btn, .submit-query-btn {
  padding: 10px 42px; 
  margin-left: 30px; 
  border-radius: 10px; 
  font-size: 22px; 
  color: #fffefe; 
  background-color: #f47c36; 
}

.close-dialog {
  width: 37px; 
  height: 35px; 
  position: absolute;
  top: -25px; 
  right: -50px; 
  background-color: red;
  background: url(images/close-dialog-button-icon.png) no-repeat center left;
}

.checkbox label:after, 
.radio label:after {
  content: '';
  display: table;
  clear: both;
}

.checkbox .cr,
.radio .cr {
  width: 25px;
  margin-left: 10px;
  position: relative;
  display: inline-block;
  border: 1px solid #959595;
  border-radius: .25em;
  background-color: #fff;
}

.radio .cr {
  height: 25px; 
  border-radius: 50%;
}

.checkbox .cr .cr-icon,
.radio .cr .cr-icon {
  position: absolute;
  font-size: 14px;
  line-height: 0;
  top: 50%;
  left: 20%;
}

.checkbox label input[type="checkbox"],
.radio label input[type="radio"] {
  display: none;
}

.checkbox label input[type="checkbox"] + .cr > .cr-icon,
.radio label input[type="radio"] + .cr > .cr-icon {
  transform: scale(3) rotateZ(-20deg);
  opacity: 0;
  transition: all .3s ease-in;
}

.checkbox label input[type="checkbox"]:checked + .cr > .cr-icon,
.radio label input[type="radio"]:checked + .cr > .cr-icon {
  transform: scale(1) rotateZ(0deg);
  opacity: 1;
}

.checkbox label input[type="checkbox"]:disabled + .cr,
.radio label input[type="radio"]:disabled + .cr {
  opacity: .5;
}

.l-title {
  padding: 10px 0 10px 60px; 
  font-size: 25px;
  color: #f47c36; 
  text-transform: uppercase; 
  background: url(images/query-modal-title-icon.png) no-repeat center left;
}