body {
	overflow-x: hidden;
}

#loading {
  position: fixed;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
  background-color: #DBDBDB;
  opacity: .7;
  -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
  filter: alpha(opacity=90); 
  z-index: 9999;
  display: none;
}

#loading>div{
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -102px;
  width: 20%;
  height: 60px;
  text-align: right;
  background: url('../images/loader.gif') no-repeat; 
  margin-top: -30px;
  padding: 8px 0 0 35px;
}

button {
	padding: 0;
	border: none;
	background: none;
}

a, p, span {
	color: #f47c36;
}

a, button, select:focus {
  outline: none;
}

a:hover, a:visited {
	text-decoration: none; 
  color: #f47c36; 
}

/*-------------------   header 
*/
#menu {
	padding-top: 17px;   
	padding-bottom: 17px; 
	margin-bottom: 0; 
	background-color: #fff;
}

#menu .navbar-toggle {
  padding: 14px 12px; 
  margin-top: 0; 
  margin-bottom: 0; 
  background-color: #f47c36;
  border: 1px solid #f47c36; 
  border-radius: 50%;
}

#menu .icon-bar {
  height: 3px; 
  background-color: #fff; 
}

#menu .navbar-brand {
  padding: 0px;
  margin-top: 25px;
  margin-right: 40px; 
  display: inline-block;
  vertical-align: middle;
}

.navbar-brand>img {
	max-width: 100%; 
	height: 48px;
  display: inline-block; 
}

#main-menu ul{
	margin-top: 35px; 
}

#menu li a {
	margin-top: 19px; 
	padding: 9px 8px; 
	font: 15px 'robotobold', sans-serif;
	color: #58585a; 
	text-transform: uppercase;
	border-top: 2px solid #fff;
	border-bottom: 2px solid #fff;
}

#menu ul .active a, #menu li a:hover {
	border-color: #f47c36;
	background-color: #fff;
}

.request-header {
  padding-top: 13px;  
  margin-left: 1px;
	display: inline-block;
}

.telephone {
	height: 35px; 
	padding-left: 45px; 
	margin-bottom: 10px;  
	background: url(images/call-icon-btn.png) no-repeat top left;
	font: 21px 'robotobold', sans-serif;
	letter-spacing: 0.05em;
	text-transform: uppercase;
	display: block; 
}

.make-request {
	padding: 8px 8px 8px 35px;
	background: #f7bb33; /* Old browsers */
	background: -moz-linear-gradient(top, #f7bb33 0%, #f47c36 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, #f7bb33 0%,#f47c36 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, #f7bb33 0%,#f47c36 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f7bb33', endColorstr='#f47c36',GradientType=0 );
	border-radius: 0;
	font: 15px 'robotobold', sans-serif;
	color: #fff;
	text-transform: uppercase;
	position: relative;
}

.make-request span {
	width: 25px; 
	height: 25px; 
	background: url(images/request-icon-btn.png) no-repeat top left;
	position: absolute;
	top: 5px;
	left: 5px;
}

/*-------------------   carousel 
*/
.fade-carousel {
  position: relative;
  height: 378px; 
}

.fade-carousel .carousel-indicators > li {
  margin: 0 2px;
  background-color: #b7b7b7; 
  border-color: #b7b7b7;
}

.fade-carousel .carousel-indicators > li.active {
  width: 10px;
  height: 10px;
  background-color: #f47c36;
  border-color: #f47c36;
}

.fade-carousel .carousel-inner .banner {
  background: url(images/first-banner-background.jpg);
  width: 100%; 
  height: 378px; 
  background-position: center center;
  -webkit-background-size: auto; 
          background-size: auto; 
}

.banner-content {
  position: absolute;
  top: 50%;
  left: 32%;
  z-index: 5;
  color: #fff;
  -webkit-transform: translate3d(-50%,-50%,0);
   -moz-transform: translate3d(-50%,-50%,0);
    -ms-transform: translate3d(-50%,-50%,0);
     -o-transform: translate3d(-50%,-50%,0);
        transform: translate3d(-50%,-50%,0);
}

.banner-content h1 {
  margin-bottom: 0;
  text-transform: uppercase; 
  font: 80px 'latolight', sans-serif;
  line-height: 1;
  color: white;
}

.banner-content .bold {
  font-family: 'latobold', sans-serif;
  color: white;
}

.banner-content a {
  display: block; 
  margin-top: 10%;
  font-family: 'helen2_bgmedium', sans-serif;
  font-size: 23px;
  margin-left: 5px;
  color: white;
}

.banner-content a span {
  color: white;
}

/*-------------------   right-menu 
*/
#new-request-option {
  background: url(images/request-right-menu-icon.png) no-repeat center left;
}

#trace-option {
  background: url(images/trace-right-menu-icon.png) no-repeat center left;
}

#redirect-option {
  background: url(images/redirect-right-menu-icon.png) no-repeat center left;
}

#calculate-option {
  background: url(images/calculate-right-menu-icon.png) no-repeat center left;
}

.right-menu-section #report-option {
  background: url(images/report-right-menu-icon.png) no-repeat center left;
}

/*-------------------   operations 
*/
#operations {
	padding: 18px 20px; 
}

#operations div[class*="col-"] {
	padding: 10px;
}

#operations img, #operations span {
	display: block; 
}

#operations img {
  height: 80px;
  margin:auto;
  margin-bottom: 10px; 
}

a.new-request,
a.trace,
a.redirect,
a.calculate,
a.report{
  text-decoration: none;
  border: 0 none;
  outline: none;
}

#operations span {
  margin:auto;
  width: 100%;
}

#operations div[class*="col-"]:last-child span {
  width: 60%; 
} 

#operations div[class*="col-"]:first-child span {
  width: 80%;
}

#operations a {
	width: 100%; 
	padding: 18px 15px 18px 15px; 
	font: 21px 'robotomedium', sans-serif;
	color: #76777a; 
	display: block; 
}

#operations a:hover {
	background-color: #f47c36; 
}
#operations a:hover span {
	color: #fff; 
}

/*-------------------   about-us
*/
.about-us { 
  width: 100%;
  padding-top: 20px; 
  padding-bottom: 20px; 
  padding-right: 0;
  margin-bottom: 50px;  
}

.about-us .row {
  -webkit-background-size: cover; 
          background-size: cover; 
  display: table;
}

.about-us .about, 
.about-us .about-us-info-image,
.references-info-image,
.references{
  float: none;
  display: table-cell;
  vertical-align: top;
  background-color: rgba(255, 255, 255, .20);
}

.about-us-info {
  background: url(images/about-us-background.jpg) no-repeat center center;
}

.references-info { 
  background: url(images/references-background.jpg) no-repeat center center;
}

.about-us .wrap-info {
  width: 68%;
  margin: 0 auto;
  padding-top: 20px; 
  padding-bottom: 25px; 
  overflow: auto;
}
.about-us-info-image,
.references-info-image {
  position: relative;
}

.about-us-info-image img {
  position: absolute;
  top: 100px; 
  left: -112px; 
}

.references-info-image img{
  position: absolute;
  bottom: 0px;
  right: 30px; 
}

.about-us .wrap-info h3 {
  padding-bottom: 5px; 
  padding-left: 50px; 
  font: 25px 'robotomedium', sans-serif;
  color: white; 
}

.about-us .wrap-info p {
  margin-bottom: 0; 
  font: 16px 'robotolight', sans-serif;
  color: #fff; 
  text-align: justify;
  display: block;   
  text-indent: 50px;
}

.about-us .wrap-info .last-paragraph {
  padding-bottom: 50px; 
}

.view-more {
  padding: 8px 10px; 
  font: 16px 'robotomedium', sans-serif;
  color: white; 
  border: 1px solid #f86223; 
  border-radius: 3px;
  float: right;
}

.view-more:hover {
  color: #fff; 
  background-color: #f47c36;
}

.about-us .wrap-info span {
  width: 100%; 
  padding-top: 8px; 
  padding-bottom: 15px; 
  font: 16px 'robotolight_italic', sans-serif;
  color: #fff; 
  float: right;
}

/*-------------------   partners
*/
.full-width {
  width: 100%;
}

.partners {
  background-color: #f47c36; 
}

.partners p, 
.partners span {
  color: #fff; 
}

.partners p {
  margin-bottom: 0; 
  font: 39px 'robotolight', sans-serif;
  text-transform: uppercase;
  line-height: 1;
}

.partners p span {
  font-family: 'robotomedium', sans-serif;
}

.partners .content div:last-child {
  background: #f7f7f7;
  padding-left: 0; 
  padding-right: 0; 
}

.partners .content div:last-child:before {
  content: '';
  width: 25px; 
  height: 100%; 
  position: absolute;
  top: 0; 
  left: 0;
  background: url(images/left-before-image.png) no-repeat;
  z-index: 1;
}

.partners .content div:last-child:after {
  content: '';
  width: 25px; 
  height: 100%; 
  position: absolute;
  top: 0; 
  right: 0;
  background: url(images/right-after-image.png) no-repeat;
  z-index: 1;
}

.partners .partner-logos{
  margin: 0; 
  padding-top: 20px; 
  padding-bottom: 20px; 
}

.partners .list-group-horizontal .list-group-item {
  display: inline-block;
  margin-bottom: 0;
  margin-right: 0;
}

.partners a {
  padding-top: 0; 
  padding-bottom: 0; 
  background: #f7f7f7; 
  border-color: #f7f7f7; 
}

/*-------------------   info-news
*/
.info-news {
	padding-top: 40px; 
	padding-bottom: 50px; 
}

.info-news h5 {
  margin-bottom: 20px; 
  font: 21px 'robotomedium', sans-serif;
  color: #76777a;  
}

.info-news .info-title {
  padding: 20px 30px 20px 30px; 
	background-color: #000;
	opacity: .57;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #fff; 
  position: absolute;
  right: 30px;
  bottom: 0;
  left: 30px;
}

.info-news .info-title span {
  font: 16px 'robotoregular', sans-serif; 
  color: #fff;
}

.info-new .arrow-span {
  position: absolute; 
  bottom: 0; right: 0; 
  background-color: red;
}

.info-news  img {
  margin: 0 auto; 
}

.info-news .carousel-control.left{
  background: url(images/arrow-left-icon.png) no-repeat left center;
  text-align: left; 
}
.info-news .carousel-control.right {
	background: url(images/arrow-right-icon.png) no-repeat right center;
  text-align: right; 
}

/*-------------------   social-media
*/
.social-media {
	background: #0a5da8;
}

.social-media .row:after, .social-media .row:before {
   display: inline-block;
}

.wrap {
	width: 87%;
	padding: 0 15px; 
	margin: 0 auto;
}

.content {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.social-media .list-group-horizontal .list-group-item {
  display: inline-block;
	margin-bottom: 0;
	margin-left: -4px;
	margin-right: 0;
	border-right: 4px solid white;
}

.social-media-section {
  padding: 0;
  overflow: auto;
}

.social-media-items {
  margin: 0; 
  float: right;
}

.social-media-items a {
  padding-top: 15px; 
  padding-bottom: 15px; 
	background-color: #0a5da8;
	border: none;
}

.social-media-items a:hover, .social-media-items a:visited {
	border-right: 4px solid white;
	background-color: #0a5da8;
}

.social-media .list-group-horizontal .list-group-item:first-child {
	border-left: 4px solid white;
}
.social-media .list-group-horizontal .list-group-item:last-child {}

/*-------------------   footer 
*/
.footer {
	padding-top: 30px; 
	padding-bottom: 30px; 
	background-color: #fff; 
	font-size: 14px; 
	font-family: 'robotolight', sans-serif;
}

.footer .copyright {
	padding-top: 10px; 
}

.footer .copyright p {
	margin-bottom: 0px; 
	color: #000; 
}

.footer .copyright p:last-child {
  margin-bottom: 20px; 
}

.footer h4 {
	padding-bottom: 10px; 
	font-size: 14px; 
	color: #000; 
	border-bottom: 1px solid #a3a3a4; 
	text-transform: uppercase; 
}

.footer a {
	padding-top: 4px; 
	padding-bottom: 4px; 
	display: block; 
}

.footer span {
	margin-right: 7px; 
}

.contacts p{
	font-family: 'robotobold', sans-serif;; 
}

.log-reg {
  margin-top: 0% !important;
  margin-bottom: 0% !important;
}

.submit-btn2 {
  padding: 3px 15px; 
  margin-left: 30px; 
  font-size: 15px; 
  color: #fffefe; 
  background-color: #f47c36; 
  margin-top: 3%;
  margin-bottom: 6%;
}

.submit-btn3 {
  padding: 3px 15px;
  margin-left: 10px;
  font-size: 15px;
  color: #fffefe;
  background-color: #f47c36;
  margin-top: 3%;
  margin-bottom: 6%;
}

.submit-btn4 {
  padding: 3px 15px;
  margin-left: 15px;
  font-size: 15px;
  color: #fffefe;
  background-color: #f47c36;
  margin-top: 3%;
}

.pagination>.active>a, .pagination>.active>a:focus, .pagination>.active>a:hover, .pagination>.active>span, .pagination>.active>span:focus, .pagination>.active>span:hover {
  background-color: #f47c36 !important;
  border-color: #f47c36 !important;
  color: white !important;
}

.pagination>.disabled>a, .pagination>.disabled>a:focus, .pagination>.disabled>a:hover, .pagination>.disabled>span, .pagination>.disabled>span:focus, .pagination>.disabled>span:hover {
  color: #777 !important;
}

.pagination>li>a, .pagination>li>span {
  color: #f47c36 !important;
}

div#datatable_length {
  margin-left: -125px !important;
}

div#datatable_filter {
  margin-left: 600px !important;
}

div#datatable_wrapper {
  margin-top: 4% !important;
}

form.track button:hover {
  background: #f26222;
}

form.track {
  margin-top: 15%;
  margin-bottom: 35%;
}

form.track::after {
  content: "";
  clear: both;
  display: table;
}

form.track button {
  float: left;
  width: 20%;
  padding: 10px;
  background: #f47c36;
  color: white;
  font-size: 17px;
  border: 1px solid #f47c36;
  border-left: none;
  cursor: pointer;
  border-radius: 3px;
}

form.track input[type=text] {
  padding: 10px;
  font-size: 17px;
  border: 1px solid #f47c36;
  float: left;
  width: 80%;
  background: #f1f1f1;
  border-radius: 3px;
}

form.import button:hover {
  background: #f26222;
}

form.import {
  margin-top: 15%;
  margin-bottom: 35%;
}

form.import::after {
  content: "";
  clear: both;
  display: table;
}

form.import button {
  float: left;
  width: 20%;
  padding: 10px;
  background: #f47c36;
  color: white;
  font-size: 17px;
  border: 1px solid #f47c36;
  border-left: none;
  cursor: pointer;
  border-radius: 3px;
}

form.import input[type=text] {
  padding: 10px;
  font-size: 17px;
  float: left;
  border-radius: 3px;
}

.inf {
  margin-top: 8%;
  color: #f47c36 !important;
  font-style: italic !important;
  font-weight: bold !important;
}

.ship-info {
  margin-top: 14%;
  margin-bottom: 25%;
}

.shift-r > .form-group {
  margin-bottom: 13px;
}

.change {
  padding: 0 !important; 
  text-align: center
}

.change li a {
  color: #f47c36 !important;
}

.import-browse {
  height: auto !important;
}

.tel {
  text-decoration: none !important;
}

.navbar-nav>li {
  padding: 0 10px !important;
}

div.express {
  border: 1px solid red;
  font-size: 18px;
  padding: 10px;
  text-align: center;
}

.about-us-head {
  font-size: 24px !important; color: #f47c36 !important;
}

.row.clear-table:before, .row.clear-table:after {
  display: none !important;
}

.pac-container {
    background-color: #fff;
    position: absolute!important;
    z-index: 10000 !important;
    border-radius: 2px;
    border-top: 1px solid #d9d9d9;
    font-family: Arial, sans-serif;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    overflow: hidden
}

.pac-logo:after {
    content: "";
    padding: 1px 1px 1px 0;
    height: 16px;
    text-align: right;
    display: block;
    background-image: url(https://maps.gstatic.com/mapfiles/api-3/images/powered-by-google-on-white3.png);
    background-position: right;
    background-repeat: no-repeat;
    background-size: 120px 14px
}
.hdpi.pac-logo:after {
    background-image: url(https://maps.gstatic.com/mapfiles/api-3/images/powered-by-google-on-white3_hdpi.png)
}
.pac-item {
    cursor: default;
    padding: 0 4px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    line-height: 30px;
    text-align: left;
    border-top: 1px solid #e6e6e6;
    font-size: 11px;
    color: #999
}
.pac-item:hover {
    background-color: #fafafa
}
.pac-item-selected,
.pac-item-selected:hover {
    background-color: #ebf2fe
}
.pac-matched {
    font-weight: 700
}
.pac-item-query {
    font-size: 13px;
    padding-right: 3px;
    color: #000
}
.pac-icon {
    width: 15px;
    height: 20px;
    margin-right: 7px;
    margin-top: 6px;
    display: inline-block;
    vertical-align: top;
    background-image: url(https://maps.gstatic.com/mapfiles/api-3/images/autocomplete-icons.png);
    background-size: 34px
}
.hdpi .pac-icon {
    background-image: url(https://maps.gstatic.com/mapfiles/api-3/images/autocomplete-icons_hdpi.png)
}
.pac-icon-search {
    background-position: -1px -1px
}
.pac-item-selected .pac-icon-search {
    background-position: -18px -1px
}
.pac-icon-marker {
    background-position: -1px -161px
}
.pac-item-selected .pac-icon-marker {
    background-position: -18px -161px
}
.pac-placeholder {
    color: gray
}

.jay-signature-pad {
    position: relative;
    display: -ms-flexbox;
    -ms-flex-direction: column;
    width: 100%;
    height: 100%;
    max-width: 500px;
    max-height: 315px;
    border: 1px solid #e8e8e8;
    background-color: #fff;
    box-shadow: 0 3px 20px rgba(0, 0, 0, 0.27), 0 0 40px rgba(0, 0, 0, 0.08) inset;
    border-radius: 15px;
    padding: 20px;
}
.txt-center {
    text-align: -webkit-center;
}
