/*-------------------   about-us-template
*/
.jumbotron {
  padding-bottom: 0; 
  height: auto; 
}

#about-us {
  background: url('images/about-us-background-image.jpg') no-repeat center top;
}

.jumbotron .container {
  padding:  50px 200px 0 200px; 
  background-color: rgba(255, 255, 255, .86);
  position: relative;
}

.jumbotron .row {
  margin-bottom: 45px; 
}

.jumbotron h3 {
  margin-bottom: 18px;
  font: 23px 'robotomedium', sans-serif;
  color: #f47c36;
}

.jumbotron h2 {
  margin-bottom: 18px;
  font: 23px 'robotomedium', sans-serif;
  color: #f47c36;
}

#customers h1 {
  margin-bottom: 18px;
  font: 23px 'robotomedium', sans-serif;
  color: #f47c36;
}

.jumbotron #services-head {
  margin-bottom: 24px !important;
  color: #f47c36 !important;
  font: 23px 'robotobold', sans-serif;
}

.jumbotron p {
  margin-bottom: 0; 
  font: 16px 'robotoregular', sans-serif;
  color: #58585a; 
}

.jumbotron .follow-steps-title {
  padding: 20px 0; 
}

.jumbotron .follow-steps-title,
.jumbotron .follow-steps li p,
.jumbotron .follow-steps li:before {
  font-family: 'robotobold', sans-serif;
}

.jumbotron .follow-steps {
  padding: 0;
  list-style: none;
  counter-reset: item;
}

.jumbotron .follow-steps li {
  padding-left: 20px; 
  position: relative;
}

.jumbotron .follow-steps li:before {
  content: counter(item) ". ";
  counter-increment: item;
  position: absolute;
  top: 0; 
  left: 0; 
  font-size: 16px;
  color: #58585a; 
}

/*-------------------   services-template
*/
#services {
  background: url('images/services-background-images.jpg') no-repeat center top;
}

#services .container {
	padding-top: 90px; 
}

#container-title {
	font-family: 'robotobold', sans-serif;
}

#services table {
	margin-bottom: 30px; 
	border-collapse: separate;
    border-spacing: 4px;
    opacity: 1;
}

#services table th {
	font: 19px 'robotomedium', sans-serif;
	color: #fff; 
	text-transform: uppercase; 
	text-align: center; 
	background-color: #f47c36; 
	border-bottom: none;
	border-radius: 3px;
}

#services table td {
	font: 19px 'robotomedium', sans-serif;
	color: #58585a; 
	border-top: none;
	text-align: center;
}

#services table td {
	background-color: rgba(255, 255, 255, 1);
	border-radius: 3px;
}

#services h1 {
	padding: 11px 16px; 
	font: 23px 'robotobold', sans-serif;
	color: #fff; 
	text-transform: uppercase; 
	background-color: #f47c36;
	line-height: 1; 
	display: inline-block; 
	border-radius: 2px; 
	position: absolute;
	top: 30px; 
	left: 65px; 
}

#services h4 {
	margin-bottom: 0; 
	font: 17px 'robotobold', sans-serif;
	color: #58585a; 
	text-transform: uppercase; 
}

.additional-services ul,
#services ul {
	padding-left: 20px; 
}

#services_usl {
  background: url('images/services-background-images.jpg') no-repeat center top;
}

#services_usl .container {
	padding-top: 90px;
}

#container-title {
	font-family: 'robotobold', sans-serif;
}

#services_usl table {
	margin-bottom: 30px;
	border-collapse: separate;
    border-spacing: 4px;
    opacity: 1;
}

#services_usl table th {
	font: 19px 'robotomedium', sans-serif;
	color: #fff;
	text-transform: uppercase;
	text-align: center;
	background-color: #f47c36;
	border-bottom: none;
	border-radius: 3px;
}

#services_usl table td {
	font: 19px 'robotomedium', sans-serif;
	color: #58585a;
	border-top: none;
	text-align: center;
}

#services_usl table td {
	background-color: rgba(234, 234, 234, 1);
	border-radius: 3px;
}

#services_usl h4 {
	margin-bottom: 0;
	font: 17px 'robotobold', sans-serif;
	color: #58585a;
	text-transform: uppercase;
}

.additional-services ul,
#services_usl ul {
	padding-left: 20px;
}

#services_rq {
  background: url('images/services-background-images.jpg') no-repeat center top;
}

#services_rq .container {
	padding-top: 90px; 
}

#container-title {
	font-family: 'robotobold', sans-serif;
}

#services_rq .table {
	margin-bottom: 30px; 
	margin-left: -170px;
	width: 1111px;
	max-width: 1111px;
	border-collapse: separate;
    border-spacing: 1px;
    opacity: 1;
}

#services_rq table th {
	color: #fff; 
	text-transform: uppercase; 
	text-align: center; 
	background-color: #f47c36; 
	border-bottom: none;
	border-radius: 3px;
}

#services_rq table td {
	color: #58585a; 
	border-top: none;
	text-align: center;
}

#services_rq table td {
	background-color: rgba(255, 255, 255, 1);
	border-radius: 3px;
}

#services_rq h1 {
	padding: 9px 16px; 
	font: 23px 'robotobold', sans-serif;
	color: #fff; 
	text-transform: uppercase; 
	background-color: #f47c36;
	line-height: 1; 
	display: table;
	border-radius: 2px; 
	position: absolute;
	text-align: center;
	top: 30px; 
	left: 65px; 
	height: 40px;
}

#services_rq h4 {
	margin-bottom: 0; 
	font: 17px 'robotobold', sans-serif;
	color: #58585a; 
	text-transform: uppercase; 
}

.additional-services ul,
#services_rq ul {
	padding-left: 20px; 
}

.additional-services ul p {
	font-family: 'robotomedium', sans-serif;
}

.additional-services ul li,
#work ul li {
  list-style: disc;
}

/*-------------------   customers-template
*/
#customers {
  background: url('images/customers-background-image.jpg') no-repeat center top;
}

#customers ul {
	padding: 0; 
  	list-style: none;
}

#customers ul li {
	margin: 10px 0 30px 0; 
}

#customers li p,
#customers li span,
#customers li a {
	display: block; 
}

#customers ul li p {
	text-align: justify;
	text-indent: 20px;
	font: 16px 'robotoitalic', sans-serif;
	color: #2a2a2a; 
}

#customers ul a {
	font: 14px 'robotomedium', sans-serif;
}

#customers ul span {
	padding-bottom: 7px; 
	font: 14px 'robotobold', sans-serif;
	color: #2a2a2a; 
}

/*-------------------   ccontacts-template
*/
#contact {
  background: url('images/services-background-images.jpg') no-repeat center top;
}

#contact h1 {
  margin-bottom: 18px;
  font: 23px 'robotomedium', sans-serif;
  color: #f47c36;
}

#contact ul {
	padding: 0;
	list-style: none;
}

#contact ul li {
	margin: 10px 0 30px 0;
}

#contact li p,
#contact li span,
#contact li a {
	display: block;
}

#contact ul li p {
	text-align: justify;
	text-indent: 20px;
	font: 16px 'robotoitalic', sans-serif;
	color: #2a2a2a;
}

#contact ul a {
	font: 14px 'robotomedium', sans-serif;
}

#contact ul span {
	padding-bottom: 7px;
	font: 14px 'robotobold', sans-serif;
	color: #2a2a2a;
}

/*-------------------   dynamic-content-template
*/

/*-------------------   customers-template
*/
#dynamic {
  background: url('images/services-background-images.jpg') no-repeat center top;
}

#dynamic ul {
	padding: 0; 
  	list-style: none;
}

#dynamic ul li {
	margin: 10px 0 30px 0; 
}

#dynamic li p,
#dynamic li span,
#dynamic li a {
	display: block; 
}

#dynamic ul li p {
	text-align: justify;
	text-indent: 20px;
	font: 16px 'robotoitalic', sans-serif;
	color: #2a2a2a; 
}

#dynamic ul a {
	font: 14px 'robotomedium', sans-serif;
}

#dynamic ul span {
	padding-bottom: 7px; 
	font: 14px 'robotobold', sans-serif;
	color: #2a2a2a; 
}

/*-------------------   work-template
*/
#work {
  background: url('images/work-background-image.jpg') no-repeat center top;
}

#work h3 {
  margin-bottom: 40px; 
}

#work ul {
  padding-left: 25px; 
}

#work .container h4 {
  margin: 25px 0; 
  font-size: 20px;
  color: #58585a; 
}

#work .welcome-to-us {
  padding: 20px 0; 
}

#work .click-to-fill {
  color: #f47c36; 
}

#btn-show {
	text-decoration: none;
	color: #f47c36;
}

#map {
	height: 330px;
	width: 100%;
}



/*-------------------   about-us-template
*/
.jumbotron {
  padding-bottom: 0; 
  height: auto; 
}

#about-us {
  background: url('images/about-us-background-image.jpg') no-repeat center top;
}

.jumbotron .container {
  padding:  50px 200px 0 200px; 
  background-color: rgba(255, 255, 255, .86);
  position: relative;
}

.jumbotron .row {
  margin-bottom: 45px; 
}

.jumbotron h3 {
  margin-bottom: 18px; 
  font: 23px 'robotomedium', sans-serif;
  color: #f47c36; 
}

.jumbotron p {
  margin-bottom: 0; 
  font: 16px 'robotoregular', sans-serif;
  color: #58585a; 
}

.jumbotron .follow-steps-title {
  padding: 20px 0; 
}

.jumbotron .follow-steps-title,
.jumbotron .follow-steps li p,
.jumbotron .follow-steps li:before {
  font-family: 'robotobold', sans-serif;
}

.jumbotron .follow-steps {
  padding: 0;
  list-style: none;
  counter-reset: item;
}

.jumbotron .follow-steps li {
  padding-left: 20px; 
  position: relative;
}

.jumbotron .follow-steps li:before {
  content: counter(item) ". ";
  counter-increment: item;
  position: absolute;
  top: 0; 
  left: 0; 
  font-size: 16px;
  color: #58585a; 
}

/*-------------------   services-template
*/
#services {
  background: url('images/services-background-images.jpg') no-repeat center top;
}

#services .container {
	padding-top: 90px; 
}

#container-title {
	font-family: 'robotobold', sans-serif;
}

#services table {
	margin-bottom: 30px; 
	border-collapse: separate;
    border-spacing: 4px;
    opacity: 1;
}

#services table th {
	font: 19px 'robotomedium', sans-serif;
	color: #fff; 
	text-transform: uppercase; 
	text-align: center; 
	background-color: #f47c36; 
	border-bottom: none;
	border-radius: 3px;
}

#services table td {
	font: 19px 'robotomedium', sans-serif;
	color: #58585a; 
	border-top: none;
	text-align: center;
}

#services table td {
	background-color: rgba(255, 255, 255, 1);
	border-radius: 3px;
}

#services h1 {
	padding: 11px 16px; 
	font: 23px 'robotobold', sans-serif;
	color: #fff; 
	text-transform: uppercase; 
	background-color: #f47c36;
	line-height: 1; 
	display: inline-block; 
	border-radius: 2px; 
	position: absolute;
	top: 30px; 
	left: 65px; 
}

#services h4 {
	margin-bottom: 0; 
	font: 17px 'robotobold', sans-serif;
	color: #58585a; 
	text-transform: uppercase; 
}

.additional-services ul,
#services ul {
	padding-left: 20px; 
}

#services_rq {
  background: url('images/services-background-images.jpg') no-repeat center top;
}

#services_rq .container {
	padding-top: 90px; 
}

#container-title {
	font-family: 'robotobold', sans-serif;
}

#services_rq .table {
	margin-bottom: 30px; 
	margin-left: -170px;
	width: 1111px;
	max-width: 1111px;
	border-collapse: separate;
    border-spacing: 1px;
    opacity: 1;
}

#services_rq table th {
	color: #fff; 
	text-transform: uppercase; 
	text-align: center; 
	background-color: #f47c36; 
	border-bottom: none;
	border-radius: 3px;
}

#services_rq table td {
	color: #58585a; 
	border-top: none;
	text-align: center;
}

#services_rq table td {
	background-color: rgba(255, 255, 255, 1);
	border-radius: 3px;
}

#services_rq h1 {
	padding: 9px 16px; 
	font: 23px 'robotobold', sans-serif;
	color: #fff; 
	text-transform: uppercase; 
	background-color: #f47c36;
	line-height: 1; 
	display: table;
	border-radius: 2px; 
	position: absolute;
	text-align: center;
	top: 30px; 
	left: 65px; 
	height: 40px;
}

#services_rq h4 {
	margin-bottom: 0; 
	font: 17px 'robotobold', sans-serif;
	color: #58585a; 
	text-transform: uppercase; 
}

.additional-services ul,
#services_rq ul {
	padding-left: 20px; 
}

.additional-services ul p {
	font-family: 'robotomedium', sans-serif;
}

.additional-services ul li,
#work ul li {
  list-style: disc;
}

/*-------------------   customers-template
*/
#customers {
  background: url('images/customers-background-image.jpg') no-repeat center top;
}

#customers ul {
	padding: 0; 
  	list-style: none;
}

#customers ul li {
	margin: 10px 0 30px 0; 
}

#customers li p,
#customers li span,
#customers li a {
	display: block; 
}

#customers ul li p {
	text-align: justify;
	text-indent: 20px;
	font: 16px 'robotoitalic', sans-serif;
	color: #2a2a2a; 
}

#customers ul a {
	font: 14px 'robotomedium', sans-serif;
}

#customers ul span {
	padding-bottom: 7px; 
	font: 14px 'robotobold', sans-serif;
	color: #2a2a2a; 
}

/*-------------------   dynamic-content-template
*/

/*-------------------   customers-template
*/
#dynamic {
  background: url('images/services-background-images.jpg') no-repeat center top;
}

#dynamic ul {
	padding: 0; 
  	list-style: none;
}

#dynamic ul li {
	margin: 10px 0 30px 0; 
}

#dynamic li p,
#dynamic li span,
#dynamic li a {
	display: block; 
}

#dynamic ul li p {
	text-align: justify;
	text-indent: 20px;
	font: 16px 'robotoitalic', sans-serif;
	color: #2a2a2a; 
}

#dynamic ul a {
	font: 14px 'robotomedium', sans-serif;
}

#dynamic ul span {
	padding-bottom: 7px; 
	font: 14px 'robotobold', sans-serif;
	color: #2a2a2a; 
}

/*-------------------   work-template
*/
#work {
  background: url('images/work-background-image.jpg') no-repeat center top;
}

#work h3 {
  margin-bottom: 40px; 
}

#work ul {
  padding-left: 25px; 
}

#work .container h4 {
  margin: 25px 0; 
  font-size: 20px;
  color: #58585a; 
}

#work .welcome-to-us {
  padding: 20px 0; 
}

#work .click-to-fill {
  color: #f47c36; 
}

#btn-show {
	text-decoration: none;
	color: #f47c36;
}

#map {
	height: 330px;
	width: 100%;
}

.telephone a {
	text-decoration: none;
}

.banner-content h2 {
	color: #f47c36;
	margin-left: 5px;
}

.panel-inner {
	margin-left: 5px;
	margin-top: 15px;
}

.img-responsive {
	width: 100% !important;
}

.panel-s: {
	transition: all .2s ease-in-out;
}
.panel-s:hover {
	-ms-transform: scale(1.1); /* IE 9 */
	-webkit-transform: scale(1.1); /* Safari 3-8 */
	transform: scale(1.1);
	border: 2px solid #f47c36;
}